import React from "react";

import { ReporteContext } from "../../Context/reporteContext";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Tooltip,
} from "@chakra-ui/react";

import Historial from "../Historia/Historial";
import DrawerManager from "../../Common/Drawer/DrawerManager";
import { MdSearch } from "react-icons/md";
import ActionButton from "../../Common/ActionButton/ActionButton";
import { fechaAmigable, fechaCorta } from "../../Services/UltilsService";
const Results = () => {
  //
  const { hidePanel, rows } = React.useContext(ReporteContext);

  const getObj = (obj) => (parseInt(obj) === 0 ? `-OBJ:${obj}` : "");

  const withToolTip = (label, content) => (
    <Tooltip label={label}>{content}</Tooltip>
  );
  if (hidePanel) return null;

  return (
    <Box
      borderRadius="lg"
      bgColor="white"
      shadow="lg"
      mt="2vh !important"
      width="auto"
    >
      <Box p={4} overflowX="auto">
        <Table variant="striped" colorScheme="gray" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Ingreso Dato</Th>
              <Th>F. Venta</Th>
              <Th>Vendedor</Th>
              <Th>Alumno</Th>
              <Th>Id Usuario</Th>
              <Th>Acuerdo</Th>
              <Th>Cobrado</Th>
              <Th>Medio Pago</Th>
              <Th>Anuncio</Th>
              <Th>Curso</Th>
              <Th>Pais</Th>
              <Th>Estado</Th>
              <Th>Accion</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rows?.map((row, i) => {
              const anuncioAmigable = row.anuncioAmigable
                ? " (" + row.anuncioAmigable + ")"
                : "";

              return (
                <Tr>
                  <Td>{i + 1}</Td>

                  <Td _hover={{ cursor: "pointer" }}>
                    {withToolTip(
                      fechaAmigable(row.fechaIngreso),
                      fechaCorta(fechaAmigable(row.fechaIngreso))
                    )}
                  </Td>
                  <Td _hover={{ cursor: "pointer" }}>
                    {withToolTip(
                      fechaAmigable(row.fechaInicio),
                      fechaCorta(fechaAmigable(row.fechaInicio)) +
                        " " +
                        row.horaventa
                    )}
                  </Td>
                  <Td>
                    {row.operador}
                    {getObj(row.objMensual)}
                  </Td>
                  <Td>
                    {row.esAlumno == 1
                      ? row.nombreAlumno
                      : `(N) ${row.nombreAlumno}`}
                  </Td>
                  <Td>{row.idUsuario}</Td>
                  <Td>${row.acuerdo}</Td>
                  <Td>${row.importeCobrado}</Td>
                  <Td>{row.descripcionMedioPago}</Td>
                  <Td>{row.anuncio + anuncioAmigable}</Td>
                  <Td>{withToolTip(row.nombreCurso, row.codigo)}</Td>
                  <Td>{withToolTip(row.pais, row.isoPais)}</Td>
                  <Td>{row.descripcionEstado}</Td>
                  <Td>
                    <DrawerManager
                      trigger={
                        <ActionButton
                          iconComponent={<MdSearch size={25} color="206cc6" />}
                        />
                      }
                      size="lg"
                      title="Historia"
                      component={
                        <Historial
                          idContacto={row.idContacto}
                          idVenta={row.idVenta}
                        />
                      }
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Results;
