import { addQueryParamsToUrl, parseDataLink } from "../Utils/utilFunctions";
import { getCookie } from "./AuthService";
import axonApi from "./AxonApi";
import { clearData } from "./UltilsService";

export const fetchAulasByEstado = async (idServicio, estado) => {
  const idCurso = idServicio == 10 ? 86 : idServicio;
  const res = await axonApi.get(
    `/academico/cursos/${idCurso}/aulas?estado=${estado}`
  );

  if (res.data.error) {
    return [];
  }

  return res.data;
};

export const generateLink = async (data) => {
  if (!data.email && !data.idContacto) {
    let url = `https://checkout.axontraining.com?idServicio=${data.idServicio}`;
    url += "tag=linkVendedor&";

    if (data.idAula) url += `&idAula=${data.idAula}`;
    if (data.usuarioResponsable)
      url += `&responsable=${data.usuarioResponsable}`;

    return {
      url: url,
      status: 200,
    };
  }

  try {
    const res = await axonApi.post(`/ventas`, clearData(data));
    return res;
  } catch (error) {
    throw error.response;
  }
};

// export const generateLinkLP = async (data) => {
//   let url = "https://axontraining.com/cursos/carrera-de-coaching-icf";
//   url += "?inscripcion";
//   url += "&tag=linkVendedor";

//   // Cuando no conoce el email
//   if (!data.idVenta) {
//     data.responsable = data.operador;

//     delete data.sinEmail;
//     delete data.idContacto;
//     delete data.operador;
//     delete data.email;

//     return addQueryParamsToUrl(url, clearData(data));
//   }

//   // Cuando conoce el email
//   if (!data.idContacto) {
//     const res = await axonApi.get(
//       `internos/crm/usuarios?email=${data.email}&userType=contacto`
//     );

//     data.idc = res.data[0].id;
//   } else {
//     data.idc = data.idContacto;
//   }

//   if (data.email && data.idVenta) {
//     try {
//       const res = await axonApi.post(
//         `/internos/crm/ventas/${data.idVenta}/plan-cuotas-lp`
//       );
//     } catch (error) {
//       throw error.response;
//     }
//   }

//   data.responsable = data.operador;
//   delete data.idContacto;
//   delete data.operador;
//   delete data.email;
//   delete data.sinEmail;

//   return addQueryParamsToUrl(url, clearData(data));
// };
export const generateLinkLP = async (data) => {
  const token = await getCookie("token_empleados");
  delete data.idContacto;
  if (data.email && data.idVenta) {
    try {
      let res = await axonApi.post(
        `/internos/crm/landing-page`,
        data,
        {
          headers: {
            Authorization:
            `Bearer ${token}`,
          },
        }
      );
      return res.data.data.url;
    } catch (error) {
      throw error.response;
    }
  }
  return 'Error Generando el Link';
};
